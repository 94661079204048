<template>
  <v-container>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="InvtDetails"
        :options.sync="tableOptions"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': tableOptions.rowsPerPageItems,
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip :key="index" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="goToProfile(item.id)"
              >
                mdi-eye
              </v-icon>
            </template>
            View
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <user-form
      :open="userFormOpen"
      user-type="professional"
      @close="userModalClose"
    />
  </v-container>
</template>
<script>
import userForm from "@/components/userForm";
import { getAllUserWithVipCode } from "@/services/users";
import DateFilter from "@/components/dateFilter";

export default {
  components: { userForm, DateFilter },
  data() {
    return {
      headers: [
        {
          text: "First Name",
          align: "start",
          sortable: false,
          value: "firstName",
          width: "100px",
        },
        {
          text: "Last Name",
          align: "start",
          sortable: false,
          value: "lastName",
          width: "100px",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
          width: "140px",
        },
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
          width: "140px",
        },
        { text: "Actions", value: "actions", sortable: false, width: "100px" },
      ],
      InvtDetails: [],
      userInvt: null,
      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        totalItems: 0,
        rowsPerPageItems: [5, 10, 50, 100, 200],
      },
      loading: true,
    };
  },
  async mounted() {
    this.getUsersData();
  },
  methods: {
    async getUsersData() {
      this.loading = true;
      
      getAllUserWithVipCode(this.$route.params.code).then(async (res) => {
        this.userInvt = res.data;
        this.loading = false;
        this.userInvt.forEach((element) => {
          const row = {
            id: element.User.id,
            firstName: element.User.first_name,
            lastName: element.User.last_name,
            email: element.User.email,
            code: element.invite_code,
          };
          this.InvtDetails.push(row);
       
        });
      });
    },
    goToProfile(id) {
      this.$router.push({ name: "Customer Profile", params: { id: id } });
    },
  },
};
</script>



